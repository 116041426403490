<template>
  <a-card :bordered="false" style="width: 100%">


    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      bordered size="middle"></a-table>

<!--

    <a-descriptions title="系统信息" bordered size="small" :column="{  md: 1}" style="margin-top: 20px">
      <a-descriptions-item label="系统架构"> {{info.sys.osArch}} </a-descriptions-item>
      <a-descriptions-item label="系统版本"> {{info.sys.osName}} </a-descriptions-item>
    </a-descriptions>


    <a-descriptions title="CPU信息" bordered size="small" :column="{  md: 1}"  style="margin-top: 20px">
      <a-descriptions-item label="核心数"> {{info.cpu.cpuNum}}核 </a-descriptions-item>
      <a-descriptions-item label="系统占用"> {{info.cpu.sys}} %</a-descriptions-item>
      <a-descriptions-item label="已使用"> {{info.cpu.used}} %</a-descriptions-item>
      <a-descriptions-item label="空闲"> {{info.cpu.free}} %</a-descriptions-item>
    </a-descriptions>

    <a-descriptions title="内存信息" bordered size="small" :column="{  md: 1}"  style="margin-top: 20px">
      <a-descriptions-item label="总内存"> {{info.mem.total}} G </a-descriptions-item>
      <a-descriptions-item label="已使用"> {{info.mem.used}} G</a-descriptions-item>
      <a-descriptions-item label="空闲"> {{info.mem.free}} G</a-descriptions-item>
      <a-descriptions-item label="使用率"> {{info.mem.usage}} %</a-descriptions-item>
    </a-descriptions>

    <a-descriptions title="JVM信息" bordered size="small" :column="{  md: 1}"  style="margin-top: 20px">
      <a-descriptions-item label="名称"> {{info.jvm.name}} </a-descriptions-item>
      <a-descriptions-item label="版本"> {{info.jvm.version}} </a-descriptions-item>
      <a-descriptions-item label="启动时间"> {{info.jvm.startTime}} </a-descriptions-item>
      <a-descriptions-item label="运行时间"> {{info.jvm.runTime}} </a-descriptions-item>
    </a-descriptions>

    -->

  </a-card>

</template>
<script>
import axios from 'axios'
import moment from 'moment'
moment.locale('zh-cn')

export default {
  data () {
    return {
      info:{},
      loading: true,
      dataSource:[ ]
    }
  },
  mounted () {
    this.create()
  },
  computed:{
    columns(){
      return [
        {
          title: '模块',
          dataIndex: 'module',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {rowSpan:1},
            };

            if(row==1 || true){
              if(!this.dataSource[index].module){
                obj.attrs.rowSpan = 0;
              }else{
                for(var i=1;index+i<=this.dataSource.length;i++){
                  obj.attrs.rowSpan = i
                  if(index+i==this.dataSource.length){
                    obj.attrs.rowSpan = i
                  }else if(this.dataSource[index+i].module){
                    break;
                  }
                }
              }
            }
            return obj;
          },
        },
        {
          title: '参数',
          dataIndex: 'name',
        },
        {
          title: '状态',
          dataIndex: 'value',
        },
      ]
    }
  },
  methods: {
    create () {
      this.time = moment().format('YYYY年MM月DD日 HH时mm分ss秒')
      this.$get("monitor/getHardwareInfo").then(suc=>{
        this.info = suc.data

        this.loading=false

        this.dataSource = [

            {
              module:"系统信息",
              name:"系统架构",
              value:this.info.sys.osArch
            },
            {
              name:"系统版本",
              value:this.info.sys.osName
            },

            {
              module:"CPU信息",
              name:"核心数",
              value:this.info.cpu.cpuNum+"核"
            },
            {
              name:"系统占用",
              value:this.info.cpu.sys+"%"
            },
            {
              name:"用户占用",
              value:this.info.cpu.used+"%"
            },
          {
            name:"空闲",
            value:this.info.cpu.free+"%"
          },

          {
            module:"内存信息",
            name:"总内存",
            value:this.info.mem.total+"G"
          },
          {
            name:"已使用",
            value:this.info.mem.used+"G"
          },
          {
            name:"空闲",
            value:this.info.mem.free+"G"
          },
          {
            name:"使用率",
            value:this.info.mem.usage+"%"
          },

          {
            module:"JVM信息",
            name:"名称",
            value:this.info.jvm.name
          },
          {
            name:"版本",
            value:this.info.jvm.version
          },
          {
            name:"启动时间",
            value:this.info.jvm.startTime
          },
          {
            name:"运行时间",
            value:this.info.jvm.runTime
          },

        ]
      })
    },
    convert (value) {
      return Number(value * 100).toFixed(2)
    }
  }
}
</script>
<style lang="less">
  .jvm-info {
    width: 100%;
    table {
      width: 100%;
      tr {
        line-height: 1.5rem;
        border-bottom: 1px solid #f1f1f1;
        th {
          background: #fafafa;
          padding: .5rem;
        }
        td {
          padding: .5rem;
          .ant-tag {
            font-size: .8rem !important;
          }
        }
      }
    }
    .alert {
      margin-bottom: .5rem;
    }
  }
</style>
